import React, { Component } from "react"

/* Components */
import { Block } from "../components"


/* Page Privacy */
class Privacy extends Component {


    componentDidMount = () => {
        window.scroll(0, 0)
    }

    render = () => {
        return (
            <Block>
                <div className="privacy">
                    <div className="privacy-wrapper">
                        
                        <h1>Публичный договор-оферта интернет–магазина</h1>
                        <p>Настоящий договор между интернет-магазином https://scooter-crew.kz/ и пользователем услуг интернет-магазина, именуемым в дальнейшем «Покупатель» определяет условия приобретения товаров через сайт интернет-магазина https://scooter-crew.kz/ Настоящий договор – оферта действует с 20 мая 2023 года.</p>
                        <div className="privacy-space" />
                        

                        <p><b>1. ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ</b></p>
                        <p>1.1. «Публичная оферта» (далее – «Оферта») - публичное предложение Продавца, адресованное неопределенному кругу лиц, заключить с Продавцом договор купли-продажи товара дистанционным способом (далее - «Договор») на условиях, содержащихся в настоящей Оферте, включая все Приложения.</p>
                        <p>1.2. «Продавец» - данные продавца указаны в разделе «Контакты»</p>
                        <p>1.3. «Покупатель» - любое физическое или юридическое лицо, принявшее в полном объеме и без исключений условия оферты (акцептовавшее) настоящую оферту на нижеуказанных условиях и разместивший заказ в Интернет-магазине.</p>
                        <p>1.4. «Зарегистрированный покупатель» - Покупатель, предоставивший Продавцу свои персональные данные посредством регистрации на сайте, которые могут быть использованы Продавцом для оформления Заказа Покупателя.</p>
                        <p>1.5. «Сайт»  - https://scooter-crew.kz/</p>
                        <p>1.6. «Интернет-магазин» - интернет-магазин по продаже матрасов, одеял, подушек, простыней, а также мебели для спальни и иных сопутствующих аксессуаров, размещенных на сайте.</p>
                        <p>1.7. «Товар» - объект купли-продажи, предложенный к продаже посредством размещения в соответствующем разделе Интернет-магазина.</p>
                        <p>1.8. «Заказ» - оформленный Покупателем в Интернет-магазине запрос на покупку товаров, выбранных Покупателем в Интернет-магазине, и предоставленный Продавцу посредством сети Интернет (электронная форма, размещенная на Сайте) и/или оформленный Покупателем по телефону.</p>
                        <p>1.9. «Перевозчик» - сторонняя организация или индивидуальный предприниматель, оказывающие услуги по доставке заказанных Покупателем Товаров из пункта отправления в пункт назначения и выдающие товар Покупателю. Договор перевозки с перевозчиком заключается Покупателем самостоятельно либо Продавцом по поручению Покупателя.</p>
                        <p>Для целей настоящей оферты термины и определения в единственном числе относятся также к терминам и определениям во множественном числе и наоборот.</p>
                        <div className="privacy-space" />


                        <p><b>2. ОБЩИЕ ПОЛОЖЕНИЯ</b></p>
                        <p>2.1.	ИП «Сабденов Ж.Е» публикует настоящий договор купли-продажи, являющийся публичным договором - офертой (предложением) в адрес физических и юридических лиц в соответствии с Гражданского Кодекса Республики Казахстан (далее -ГК РК).</p>
                        <p>2.2.	Настоящая публичная оферта (именуемая в дальнейшем «Оферта») определяет все существенные условия договора между ИП «Сабденов Ж.Е» и лицом, акцептовавшим Оферту.</p>
                        <p>2.3. Настоящий договор заключается между Покупателем и интернет - магазином в момент оформления заказа.</p>
                        <p>2.4. Оферта может быть акцептована (принята) любым физическим или юридическим лицом на территории Республики Казахстан, имеющим намерение приобрести товар и/или услуги, реализуемые/предоставляемые ИП «Сабденов Ж.Е» через интернет-магазин, расположенный на сайте https://scooter-crew.kz/</p>
                        <p>2.5.  Покупатель безоговорочно принимает все условия, содержащиеся в оферте в целом (т.е. в полном объеме и без исключений).</p>
                        <p>2.6.  В случае принятия условий настоящего договора (т.е. публичной оферты интернет-магазина), физическое или юридическое лицо, производящее акцепт оферты, становится Покупателем. Акцептом является факт оплаты заказа в размере и на условиях настоящего соглашения.</p>
                        <p>2.7. Оферта, все приложения к ней, а также вся информация о товарах/услугах ИП «Сабденов Ж.Е» , опубликована на сайте https://scooter-crew.kz/</p>
                        <div className="privacy-space" />

                        
                        <p><b>3. СТАТУС ИНТЕРНЕТ-МАГАЗИНА https://scooter-crew.kz/</b></p>
                        <p>3.1. Интернет-магазин является собственностью ИП «Сабденов Ж.Е» и предназначен для организации дистанционного способа продажи товаров через сеть интернет.</p>
                        <p>3.2. Интернет-магазин не требует от Покупателя специальных действий для использования ресурса интернет - магазина для просмотра товара, расчета и оформления заказа, таких как регистрация или заключение договора на пользование ресурсом интернет-магазина.</p>
                        <p>3.3. Интернет-магазин не несет ответственности за содержание и достоверность информации, предоставленной Покупателем при оформлении заказа.</p>
                        <p>3.4. Интернет-магазин и предоставляемые сервисы могут временно частично или полностью недоступны по причине проведения профилактических или иных работ или по любым другим причинам технического характера. Техническая служба Продавца имеет право периодически проводить необходимые профилактические или иные работы с предварительным уведомлением Покупателей или без такового.</p>
                        <p>3.5. В случае возникновения вопросов и претензий со стороны Покупателя он должен обратиться к Продавцу по телефону или иным доступным способом. Все возникающее споры стороны будут стараться решить путем переговоров, при недостижении соглашения спор будет передан на рассмотрение в судебный орган в соответствии с действующим законодательством РК.</p>
                        <p>3.6. Признание судом недействительности какого-либо положения настоящего Соглашения не влечет за собой недействительность остальных положений.</p>
                        <div className="privacy-space" />

                        
                        <p><b>4. СТАТУС ПОКУПАТЕЛЯ</b></p>
                        <p>4.1. Покупатель несет ответственность за достоверность предоставленной при оформлении заказа информации и ее чистоту от претензий третьих лиц.</p>
                        <p>4.2. Покупатель подтверждает свое согласие с условиями, установленными настоящим Договором, путем проставления отметки в графе «Я принимаю условия договора-оферты» при оформлении заказа. До заключения Договора условия Договора Покупателем прочитаны полностью, все условия Договора понятны, со всеми условиями Договора Покупатель согласен.</p>
                        <p>4.3. Использование ресурса интернет - магазина для просмотра и выбора товара, а также для оформления заказа является для Покупателя безвозмездным.</p>
                        <p>4.4. Товар приобретается Покупателем исключительно для личных, семейных, домашних нужд, не связанных с осуществлением предпринимательской деятельности.</p>
                        <div className="privacy-space" />

                    
                        <p><b>5. ПРЕДМЕТ ОФЕРТЫ</b></p>
                        <p>5.1.  Продавец, на основании заказов Покупателя и на основании предварительной оплаты, продаёт Покупателю товар в соответствии с условиями и по ценам, установленным Продавцом в оферте и приложениях к ней.</p>
                        <p>5.2. Доставка товаров, заказанных и оплаченных Покупателем, осуществляется Продавцом или Перевозчиком. Покупатель имеет право забрать товар со склада Продавца самостоятельно (самовывоз). Покупателю при оформлении заказа предоставляется право выбора способа доставки.</p>
                        <p>5.3.  К отношениям между Покупателем и Продавцом применяются положения ГК РК о розничной купле-продаже, Закон РК «О защите прав потребителей», а также иные нормативные правовые акты, принятые в соответствии с ними.</p>
                        <p>5.4.  Физическое или юридическое лицо считается принявшим все условия оферты (акцепт оферты) и приложений к ней в полном объеме и без исключений с момента поступления денежных средств в счет оплаты товара на расчётный счёт Продавца (в случае безналичной оплаты), либо с момента поступления денежных средств в счет оплаты товара на расчетный счет Оператора платежной системы (в случае оплаты через платежные системы), либо внесения денежных средств в кассу Продавца в порядке, предусмотренном Разделом 10 оферты и на условиях, установленных Продавцом в приложениях к оферте. В случае акцепта оферты одним из вышеуказанных способов, физическое лицо считается заключившим с Продавцом договор купли-продажи заказанных товаров и приобретает статус Покупателя.</p>
                        <p>5.5. Покупатель-физическое или юридическое лицо, принявшее в полном объеме и без исключений условия оферты (совершившее акцепт оферты) в соответствии с п. 4.4. оферты.</p>
                        <p>5.6. Место исполнения договора -место (адрес), указанное Покупателем, по которому доставляется товар Покупателю силами Продавца, или магазин-салон или склад Продавца, в случае отказа Покупателя от доставки товара силами Продавца (самовывоз), или территория перевозчика, договор с которым заключил Покупатель, в случае доставки товара Перевозчиком.</p>
                        <p>5.7. Представитель-физическое лицо, предъявившее квитанцию или иной документ, свидетельствующий о заключении договора. Представитель юридического лица кроме вышеуказанных документов обязан предъявить доверенность на получение товара и паспорт.</p>
                        <p>5.8. Перевозчик-юридическое лицо или индивидуальный предприниматель, принявшие на себя по договору перевозки обязанность доставить вверенный ему отправителем товар из пункта отправления в пункт назначения, а также выдать товар получателю. Договор перевозки с перевозчиком заключается Покупателем самостоятельно в случае отказа Покупателя от доставки товара силами Продавца.</p>
                        <div className="privacy-space" />

                        
                        <p><b>6. ПОРЯДОК ЗАКЛЮЧЕНИЯ ДОГОВОРА КУПЛИ-ПРОДАЖИ</b></p>
                        <p>6.1. Покупатель может оформить заказ самостоятельно на сайте интернет-магазина, либо через менеджера по телефонам, указанным на сайте, на условиях Договора купли-продажи (публичной оферты интернет-магазина).</p>
                        <p>6.2. При оформлении заказа в интернет-магазине, Покупатель обязан предоставить о себе информацию:</p>
                        <p>- Ф.И.О. (для физических лиц) или полное наименование, БИН (для юридических лиц) Покупателя Товара; - адрес доставки Товара; - контактный телефон и электронную почту Покупателя Товара.</p>
                        <p>6.3. Волеизъявление Покупателя осуществляется посредством внесения последним соответствующих данных в форму заказа в интернет-магазине либо подачей заявки через менеджера интернет-магазина или по e-mail.</p>
                        <p>6.4. Интернет-магазин не редактирует информацию о Покупателе.</p>
                        <p>6.5. Для получения бумажного экземпляра Договора купли-продажи, Покупатель отправляет заявку по электронной почте или телефону указанным на сайте.</p>
                        <div className="privacy-space" />

                        <p><b>7. ИНФОРМАЦИЯ О ТОВАРЕ</b></p>
                        <p>7.1. Товар представлен на сайте через фото-образцы, являющиеся собственностью интернет-магазина.</p>
                        <p>7.2. Каждый фото-образец сопровождается текстовой информацией: наименованием, размерным рядом (при необходимости), ценой и описанием товара.</p>
                        <p>7.3. Все информационные материалы, представленные в интернет -магазине, носят справочный характер. В случае возникновения у Покупателя вопросов, касающихся свойств и характеристик товара, Покупатель должен, перед оформлением заказа, обратиться к Продавцу по телефонам указанным на сайте.</p>
                        <p>7.4. По просьбе Покупателя менеджер интернет-магазина обязан предоставить (по телефону или посредством электронной почты) прочую информацию, необходимую и достаточную, с точки зрения Покупателя, для принятия им решения о покупке товара.</p>
                        <p>7.5.  Покупатель уведомлен о том, что приобретая товар со скидкой, установленной в связи с его недостатками (дефектами), он лишается права ссылаться на них в дальнейшем.</p>
                        <p>7.6.  Покупатель уведомлен Продавцом о том, что товар, указанный в счете отдельными позициями в любом случае не является комплектом.</p>
                        <div className="privacy-space" />

                        <p><b>8. ПОРЯДОК ПРИОБРЕТЕНИЯ ТОВАРА</b></p>
                        <p>8.1. Покупатель вправе оформить заказ на любой товар, представленный в интернет-магазине. Каждый товар может быть заказан в любом количестве. Исключения из указанного правила указаны в описании каждого товара в случае проведения акций, снятия товара с продажи и т.п.</p>
                        <p>8.2. Заказ может быть оформлен Покупателем по телефонам, указанным на сайте или оформлен самостоятельно на сайте. Подробности оформления заказа через сайт описаны в разделе «Покупателям», затем «Как оформить заказ».</p>
                        <p>8.3. После оформления заказа Продавец на e-mail Покупателя отправляется подтверждение принятия заказа и счет, с указанием наименования, размера, цены выбранного товара и общей суммы заказа, являющийся неотъемлемой частью настоящего договора. Оплата счета (полностью или частично) Покупателем является подтверждением Покупателя правильного оформления заказа.</p>
                        <p>8.4. При отсутствии товара на складе менеджер интернет-магазина обязан поставить в известность об этом Покупателя (по телефону или посредством электронной почты).</p>
                        <p>8.5. Покупатель вправе сделать предварительный заказ на временно отсутствующий на складе товар.</p>
                        <p>8.6. При отсутствии товара Покупатель вправе заменить его другим товаром либо аннулировать заказ.</p>
                        <p>8.7. Срок поставки товара указывается на сайте Продавца в разделах «Сроки изготовления»-«Оплата и доставка» и начинает исчисляться на следующий день после получения Продавцом сообщения о намерении Покупателя приобрести товар на условиях, предложенных Продавцом.</p>
                        <div className="privacy-space" />

                        <p><b>9. ЦЕНА ТОВАРА</b></p>
                        <p>9.1. Цена товара в интернет-магазине указана в тенге за единицу товара. Цена товара не включает стоимость доставки товара до Покупателя и иные услуги Продавца.</p>
                        <p>9.2. Указанная на сайте цена товара может быть изменена интернет-магазином в одностороннем порядке, при этом цена на оплаченный Покупателем товар изменению не подлежит.</p>
                        <p>9.3. Стоимость услуг, предоставляемых Покупателю Продавцом при покупке товара в интернет-магазине указана в разделе «Оплата и доставка».</p>
                        <div className="privacy-space" />

                        <p><b>10. ОПЛАТА ТОВАРА</b></p>
                        <p>10.1. Способы и порядок оплаты товара указаны на сайте в разделе «Оплата и доставка». При необходимости порядок и условия оплаты заказанного товара оговариваются Покупателем с менеджером интернет-магазина.</p>
                        <p>10.2. Оплата безналичным расчетом производится согласно оформленному счёту в течение трёх банковских дней с момента получения счета. После поступления денежных средств на счет Продавца, менеджер интернет-магазина согласовывает с Покупателем срок доставки. При безналичной форме оплаты обязанность Покупателя по уплате цены товара считается исполненной с момента зачисления соответствующих денежных средств на расчетный счет, указанный Продавцом.</p>
                        <p>10.3. Покупатель оплачивает заказ любым способом, выбранным в интернет-магазине. Оплата Товара (полностью или частично) может быть произведена в кассу - ИП «Сабденов Ж.Е».</p>
                        <p>10.4.  Расчеты Сторон при оплате заказа осуществляются в тенге.</p>
                        <div className="privacy-space" />

                        <p><b>11. ДОСТАВКА ТОВАРОВ</b></p>
                        <p>11.1. Способы, порядок и сроки доставки товара указаны на сайте в разделе «Оплата и доставка». Порядок и условия доставки заказанного товара оговариваются Покупателем с менеджером интернет-магазина.</p>
                        <p>11.2. Самовывоз товара:</p>
                        <p>• Продавец, получив уведомление о размещенном заказе, подтверждает его получение по телефону или по e-mail Покупателя и согласовывает с ним дату самовывоза товара.</p>
                        <p>• Покупатель оплачивает (при наличной форме оплаты) и получает заказ по месту нахождения склада Продавца. Адреса, контакты и режим работы складов указаны на сайте Продавца в разделе «Где купить». При безналичной форме оплаты Продавец дополнительно по телефону или по e-mail Покупателя подтверждает факт зачисления оплаты заказа на расчетный счет Продавца и только после этого согласовывается с Покупателем дату самовывоза товара.</p>
                        <p>• Право собственности и риск случайной гибели, утраты или повреждения товара переходит к Покупателю с момента передачи товара Покупателю или его Представителю.</p>
                        <p>11.3. Доставка товара Продавцом:</p>
                        <p>• Переход права собственности и риск случайной гибели, утраты или повреждения товара переходит к Покупателю с момента передачи товара Покупателю или Представителю.</p>
                        <p>• Доставка осуществляется общедоступным способом –через входную дверь. Доставка товара через окна, балконы или иные проемы, кроме дверных, не осуществляется. Подъем и занос Товара в помещение не осуществляется по винтовым лестницам, лестницам без перил.</p>
                        <p>• Работы (услуги) по снятию дверей, освобождению входных проходов, перемещению, сборке/разборке имеющейся мебели и других предметов интерьера Покупателя, освобождение территории для сборки приобретенного Товара и прочие подобные работы не предусмотрены настоящим Договором.</p>
                        <p>• Работы (услуги) не оплаченные и не заказанные Покупателем не выполняются (не оказываются).</p>
                        <p>• Покупатель обеспечивает надлежащие условия для приемки Товара, включая:</p>
                        <p>a. Обеспечить личное присутствие либо присутствие Представителя в месте исполнения Договора.</p>
                        <p>b. Товар доставляется до подъезда Покупателя лишь при условии наличия свободных подъездных путей для грузового автотранспорта (габариты проезда: ширина 2,5 метра, длина 8 метров, высота 3 метра) и наличия нумерации на доме. Проезд (въезд) грузового автотранспорта на охраняемую территорию должен быть согласован Покупателем со службой охраны заранее. Место выгрузки Товара должно быть не далее, чем 15 метров до подъезда (вход в дом).</p>
                        <p>c. Обеспечить соответствие размера дверных проемов габаритам Товара (ширина проемов входной и межкомнатных дверей в помещении должна составлять по ширине –не менее 75 см, высоте не менее 190 см).</p>
                        <p>d. Обеспечить свободный и достаточный по габаритам проход до места размещения товара (свободная ширина прохода внутри помещения не должна быть менее 105 см). Предметы, препятствующие переносу Товара, необходимо убрать перед доставкой.</p>
                        <p>e. Необходимо уведомить продавца до заключения договора о несоответствии технических характеристик помещения для выяснения возможности доставки товара.</p>
                        <p>f. Заранее освободить необходимую территорию для сборки и установки доставленного товара.</p>
                        <p>• При отсутствии необходимых вышеизложенных условий, Продавец имеет право принять решение о невозможности отгрузки Товара по указанному Покупателем адресу, о чем уведомляет покупателя.</p>
                        <p>• В случае необеспечения Покупателем надлежащей приемки товара, повторная доставка (осуществляемая по вине Покупателя) производится в согласованные сторонами сроки за счет Покупателя на условиях предоплаты в соответствии с тарифами Продавца.</p>
                        <p>11.4. Доставка товара Перевозчиком:</p>
                        <p>• Право собственности и риск и риск случайной гибели, утраты или повреждения товара переходит с Продавца на Покупателя или Перевозчика (в соответствии с заключенным между Покупателем и Перевозчиком договором) с момента передачи товара Перевозчику при подписании Сторонами товарной накладной и/или транспортной накладной и/или товарно-транспортной накладной или иного документа, свидетельствующего о передаче товара Перевозчику.</p>
                        <p>• Обязательство по передачи товара Покупателю, в том числе п. 11.4.1., считается исполненным с момента передачи товара Перевозчику.</p>
                        <p>• Стоимость доставки товара в рамках каждого заказа рассчитывается исходя из веса всех заказанных товаров, адреса доставки заказа, расценок перевозчика и оплачивается Покупателем Перевозчику самостоятельно. Стоимость доставки не входит в цену товара.</p>
                        <p>11.5. Покупатель обязан принять товар по количеству и ассортименту в момент его приемки.</p>
                        <p>11.6. При получении товара Покупатель или Представитель должен в присутствии представителя Продавца или Перевозчика проверить соответствие полученного товара по наименованию, количеству, ассортименту, качеству, комплектности заказанному товару.</p>
                        <p>11.7. Покупатель или Представитель при приемке товара подтверждает своей подписью в документе о получении товара, что не имеет претензий к внешнему виду и комплектности товара.</p>
                        <div className="privacy-space" />

                        <p><b>12. ГАРАНТИИ НА ТОВАР</b></p>
                        <p>12.1. Гарантийный срок на товар устанавливает производитель. Срок гарантии указывается на этикетке товара. Дополнительные гарантийные обязательства указаны на сайте в разделе «Гарантийные обязательства» и в приложениях к настоящему договору.</p>
                        <p>12.2. Гарантийный срок на аксессуары (наматрасники, подушки, чехлы, одеяла, постельное белье и т.д.) и фурнитуру не распространяется, если иное не указано в Приложении к настоящему договору.</p>
                        <p>12.3. Гарантийный срок не распространяется на товар, имеющий повреждения, возникшие в результате:</p>
                        <p>• нарушения Покупателем правил эксплуатации и/или рекомендаций по уходу за товаром,</p>
                        <p>• использования товара не по назначению,</p>
                        <p>• действий, направленных на повреждение или уничтожение товара, действий непреодолимой силы,</p>
                        <p>• а также на товар, имеющий механические повреждения, следы самостоятельного ремонта или изменения конструкции.</p>
                        <div className="privacy-space" />

                        <p><b>13. ВОЗВРАТ ТОВАРА</b></p>
                        <p>13.1. Покупатель вправе отказаться от товара в любое время до его передачи, а после передачи товара - в течение семи дней, в порядке и на условиях, предусмотренных Законом «О защите прав потребителей».</p>
                        <p>13.2. Возврат товара надлежащего качества возможен в случае, если сохранены его товарный вид (упаковка), потребительские свойства, а также документ, подтверждающий факт и условия покупки указанного товара. Отсутствие у покупателя указанного документа не лишает его возможности ссылаться на другие доказательства приобретения товара у данного продавца.</p>
                        <p>13.3. Покупатель не вправе отказаться от товара надлежащего качества, имеющего индивидуально-определенные свойства, если указанный товар может быть использован исключительно приобретающим его Покупателем (в т.ч. не стандартные (по желанию Покупателя) размеры, ткань и др.). Подтверждением того, что вещь имеет индивидуально-определенные свойства, в том числе, но не ограничиваясь, является отличие размеров товара размерам, указанным в интернет-магазине. При покупке кровати, кровать изготавливается по индивидуальному заказу Покупателя (ткань, фурнитура (при наличии) и является товаром с индивидуально-определенными свойствами.</p>
                        <p>13.4. Возврат товара, в случаях, предусмотренных законом и настоящим Договором, производится по адресам, указанным на сайте в разделе «Где купить».</p>
                        <p>13.5. При отказе Покупателя от товара надлежащего качества Продавец возвращает ему сумму, уплаченную в соответствии с договором, за исключением расходов Продавца на доставку от Покупателя возвращенного товара, не позднее чем через 10 дней, с даты предъявления Покупателем соответствующего требования.</p>
                        <p>13.6. В случае, если возврат суммы осуществляется не одновременно с возвратом товара, возврат указанной суммы осуществляется Продавцом путем перечисления на банковский счет Покупателя.</p>
                        <p>13.7. Указанный в настоящем пункте способ возврата денежных средств может использоваться Продавцом и в иных случаях возврата денежных средств, предусмотренных настоящим договором и законодательством РК.</p>
                        <div className="privacy-space" />

                        <p><b>14. ПРОЧИЕ УСЛОВИЯ</b></p>
                        <p>14.1. К отношениям между Покупателем и Продавцом применяется законодательство Республики Казахстан.</p>
                        <p>14.2. При необходимости Продавец и Покупатель вправе в любое время оформить договор купли-продажи товара в форме письменного двухстороннего соглашения, не противоречащего положениям настоящей оферты.</p>
                        <p>14.3.В случае возникновения вопросов и претензий со стороны Покупателя, он должен обратиться в Центр обслуживания клиентов по телефону: +7 (747) 312 54 58 или по e-mail:  info@scooter-crew.kz</p>
                        <p>14.4. Настоящий договор вступает в силу с даты акцепта Покупателем настоящей оферты и действует до полного исполнения обязательств Сторонами.</p>
                        <p>14.5. Интернет-магазин оставляет за собой право расширять и сокращать товарное предложение на сайте, регулировать доступ к покупке любых товаров, а также приостанавливать или прекращать продажу любых товаров по своему собственному усмотрению.</p>
                        <div className="privacy-space" />

                        <p><b>15. АДРЕС И РЕКВИЗИТЫ ПРОДАВЦА</b></p>
                        <p>ИП «BUSINESS SOLUTIONS»</p>
                        <p>Юридический адрес: г. Алматы, ул. Рубенштайна, 52</p>
                        <p>БИН: 960629300485</p>
                        <p>mail: info@scooter-crew.kz</p>

                    </div>
                </div>
            </Block>
        )
    }
}

export default Privacy