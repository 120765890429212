import React, { Component } from "react"

/* Components */
import { Block } from "../components"


/* Page Contact */
class Contact extends Component {

    componentDidMount = () => {
        window.scroll(0, 0)
    }

    render = () => {
        return (
            <Block>
                <div className="contact">
                    <div className="contact-wrapper">

                        <h2>Контакты</h2>

                        <div className="mobile-contact">
                            <div className="mobile-contact-item">

                                <h3>г. Алматы</h3>

                                <p>Телефон</p>
                                <b>+7 (747) 312-54-58</b>

                                <p>Вопросы, отзывы и предложения:</p>
                                <b>info@scooter-crew.kz</b>

                                <div className="mobile-contact-line" />

                                <p>Адрес:</p>
                                <b>Казахстан, г.Алматы, Медеуский район, улица Рубинштейна, 52</b>

                            </div>
                        </div>
                    </div>
                </div>
            </Block>
        )
    }
}

export default Contact