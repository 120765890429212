import React, { Component } from "react"

/* React Router */
import { Link } from "react-router-dom"

/* Constants */
import { env } from '../../constants'

/* Helpers */
import { withRouter } from '../../helpers'


/* Widget Horizontal Category */
class HorizontalCategory extends Component {

    render = () => {

        const { data } = this.props

        return (
            <div className="horizontal-category">

                <div className="home-title">
                    <h2>По Категориям</h2>
                    <img src="/images/pseudo.png" alt="Pseudo" />
                    <p>Лучшие категории недели</p>
                </div>

                <div className="horizontal-category-box">
                    {Array.isArray(data) && data.length > 0 && data.map((category, index) => {

                        if (index >= 7) return <div key={`${index}`} />

                        return (
                            <Link to={`/category/${category.slug}`} key={`${index}`} className="horizontal-category-item">
                                <img src={`${env.mediapoint}categories/${category.image}`} alt={category.name} />
                                <span>{category.name}</span>
                            </Link>
                        )
                    })}
                </div>


                {/*<div className="banners">
                    <div onClick={() => navigate("/category/myaso-i-moreprodukty")} className="banner">
                        <img src="/slides/banner1.png" alt="Banner" />
                        <div className="banner-box">
                            <h2>50% Скидка</h2>
                            <p>Свежее мясо</p>
                            <div className="banner-button">Купить сейчас</div>
                        </div>
                    </div>
                    <div onClick={() => navigate("/category/ovoschi-i-frukty")} className="banner">
                        <img src="/slides/banner2.png" alt="Banner" />
                        <div className="banner-box">
                            <h2>50% Скидка</h2>
                            <p>Вкусные грибы</p>
                            <div className="banner-button">Купить сейчас</div>
                        </div>
                    </div>
                </div>*/}

            </div>
        )
    }

}

export default withRouter(HorizontalCategory)