import React, { Component } from "react"

/* Components */
import { Block } from "../components"


/* Page About */
class About extends Component {


    componentDidMount = () => {
        window.scroll(0, 0)
    }

    render = () => {
        return (
            <Block>
                <div className="about">
                    <div className="about-wrapper">
                        <h1>О магазине</h1>


                        <div className="about-box">

                            <div className="about-box-text">

                                <p>Добро пожаловать в мир скутеров!</p>

                                <p>Давайте представим вам наш интернет-магазин, где страсть к движению на двух колесах воплощается в самых современных и стильных скутерах. Мы гордимся предлагаемым ассортиментом, который удовлетворит потребности как начинающих любителей, так и опытных профессионалов. Для нас важно, чтобы каждый клиент получил не только качественный товар, но и высокий уровень сервиса, который мы гарантируем на протяжении всего пути вашей покупки и использования скутера.</p>

                                <p>Наш ассортимент:</p>

                                <p>В нашем магазине вы найдете широкий выбор скутеров от ведущих мировых производителей. Независимо от того, ищете ли вы скутер для городской езды, внедорожные приключения или спортивные соревнования, у нас есть подходящая модель для вас. Мы предлагаем:</p>

                                <p>Городские скутеры: Элегантные и эффективные модели, идеально подходящие для повседневных поездок по городу. Легкие в управлении и экономичные в использовании, они делают перемещение по городу быстрым и комфортным.</p>
                                <p>Внедорожные скутеры: Мощные и надежные скутеры, спроектированные для путешествий по бездорожью и экстремальных условиях. С ними вы сможете покорять горные тропы, пустынные дюны и лесные тропинки без труда.</p>
                                <p>Спортивные скутеры: Для тех, кто ищет адреналиновый заряд и скоростные увлечения, у нас есть спортивные модели с высокой производительностью и передовыми технологиями.</p>
                                <p>Электрические скутеры: Вклад в экологическое будущее - это то, что мы ценим. Поэтому у нас есть широкий выбор электрических скутеров, которые не только экологически чисты, но и экономичны в использовании.</p>
                                <p>Почему выбирают нас:</p>

                                <p>Качество и надежность: Мы работаем только с проверенными брендами, гарантируя высокое качество каждого скутера.</p>
                                <p>Широкий ассортимент: У нас есть скутеры для всех целей и бюджетов. Вы точно найдете то, что подходит именно вам.</p>
                                <p>Профессиональный сервис: Наша команда экспертов всегда готова помочь вам с выбором, обслуживанием и ремонтом скутера.</p>
                                <p>Удобство покупки: Благодаря нашему удобному интернет-магазину вы можете приобрести скутер прямо из дома, сэкономив время и усилия.</p>
                                <p>Безопасность: Мы придаем большое значение безопасности наших клиентов, поэтому предлагаем только сертифицированные и безопасные модели скутеров.</p>
                                <p>Гибкая система оплаты и доставки: Мы предлагаем различные варианты оплаты и оперативную доставку по всей стране.</p>
                                <p>Присоединяйтесь к нашему сообществу скутеристов уже сегодня! Передвигайтесь свободно, быстро и стильно с скутерами от нашего интернет-магазина.</p>
                            </div>

                            <img src="/images/about.jpg" alt="About" />

                        </div>
                    </div>
                </div>
            </Block>
        )
    }
}

export default About